import '../sass/frontend.scss'
import $ from "jquery";
// import Swiper bundle with all modules installed https://swiperjs.com/get-started
import Swiper from 'swiper/bundle';
// import Swiper styles bundle
import 'swiper/css/bundle';
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import {CountUp} from 'countup.js';

// ==============================================================================================================================

function logoSwiper() {
    let logo_Slider = $('.logo_slider ');
    if (logo_Slider.length >= 1) {
    $('.slider_section ').each(function () {


            var slider_ID = '#' + $(this).attr('id') + ' .logo_slider ';
            var main_section_ID = '#' + $(this).attr('id');

            let above1024 = parseInt($(this).find('.logo_slider_data').attr('data-1024'));
            let above768 = parseInt($(this).find('.logo_slider_data').attr('data-768'));
            let above320 = parseInt($(this).find('.logo_slider_data').attr('data-320'));
            let autoplay = parseInt($(this).find('.logo_slider_data').attr('data-autoplay'));
            let autoPlaySpeed = parseInt($(this).find('.logo_slider_data').attr('data-autoplay-speed'));

            if (autoplay == true) {

                var autoplayTrueFalse = {

                    delay: autoPlaySpeed,
                    disableOnInteraction: false,
                }
            } else {

                var autoplayTrueFalse = false;
            }
            var swiper = new Swiper(slider_ID, {
                navigation: {
                    nextEl: main_section_ID + ' .swiper-button-next',
                    prevEl: main_section_ID + ' .swiper-button-prev',
                },
                pagination: {
                    el: main_section_ID + " .swiper-pagination",
                    clickable: true,
                },

                autoplay: autoplayTrueFalse,

                loop: true,
                breakpoints: {
                    320: {
                        slidesPerView: above320,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: above768,
                        spaceBetween: 30,
                    },
                    1026: {
                        slidesPerView: above1024,
                        spaceBetween: 30,
                    },
                },
            });

            // let move_dots_outside = jobsHomeSlider.find('.swiper-pagination');
            // jobsHomeSlider.closest('.gb-inside-container').append(move_dots_outside);
    });
    }

}

// ==============================================================================================================================
//ACF nav scroll gallery
function scrollActive() {
    if ($('.scroll_section').length >= 1) {
        $('.scroll_nav li').on('click', function () {

            var scrollAnchor = $(this).attr('data-scroll');

            var scrollPoint = $('.content_column div[data-anchor="' + scrollAnchor + '"]').offset().top - 28;
            // console.log(scrollPoint);

            $('body,html').animate({
                scrollTop: scrollPoint
            }, 500);

            return false;

        })

        $(window).scroll(function () {
            var windscroll = $(window).scrollTop();
            if (windscroll >= 100) {
                $('.section_block ').each(function (i) {
                    if ($(this).position().top <= windscroll + 80) {
                        $('.scroll_nav li.active').removeClass('active');
                        $('.scroll_nav li').eq(i).addClass('active');
                    }
                });

            } else {


                $('.scroll_nav li.active').removeClass('active');
                // $('.scroll_nav li:first').addClass('active');
            }

        }).scroll();
    }


};

// ==============================================================================================================================

// source  https://www.npmjs.com/package/countup.js
function counte() {
    if ($('.counter').length >= 1) {
        document.querySelectorAll('.counter').forEach((element) => {
            let text = element.textContent;
            let number = parseInt(text.replace('.', ''));
            const countup = new CountUp(element, number, {
                duration: 10,
            });
            countup.start();
        });
    }
}

// ==============================================================================================================================

function tableToggler() {
    if($('.table_4_cols ').length > 0){
        if($('.table_4_cols tbody tr').length > 9){
            $('.table_4_cols tbody tr:last-child strong').on('click', function () {
                $(this).each(function () {
                    $(this).closest('.table_4_cols').toggleClass('show_less');
                });
            });
        } else {
            $('.table_4_cols tbody tr:last-child').css('display', 'none');
        }
    }
}

// ==============================================================================================================================

//init js functions
$(document).ready(function () {
    scrollActive();
    logoSwiper();
    counte();
    tableToggler();
    Fancybox.bind("[data-fancybox]", {
        // Your options go here
    });
    // $('#home_section_3 ,#home_section_4').addClass('loaded');

})